<template>
    <div>
        <div class="picbox bg-white">
            <div class="pictitle"><strong>热门区县</strong></div>
            <div class="piccont flex align-center">
                <div class="picleft flex align-center">
                    <div class="picitem" style="width:390px;" @click="changeWeb('信州区')">
                        <img src="/img/webpic1.png" alt="">
                        <span>信州区</span>
                    </div>
                    <div class="picitem" style="margin-right:0;" @click="changeWeb('广信区')">
                        <img src="/img/webpic2.png" alt="">
                        <span>广信区</span>
                    </div>
                    <div class="picitem" style="margin-bottom:0;" @click="changeWeb('广丰区')">
                        <img src="/img/webpic5.png" alt="">
                        <span>广丰区</span>
                    </div>
                    <div class="picitem" style="width:390px;margin-right:0;margin-bottom:0px;" @click="changeWeb('高铁新区')">
                        <img src="/img/webpic6.jpg" alt="">
                        <span>高铁新区</span>
                    </div>
                </div>
                <div class="picright flex align-center">
                    <div class="picitem" style="height:408px; width:200px; margin-bottom:0;" @click="changeWeb('玉山县')">
                        <img src="/img/webpic3.png" alt="">
                        <span>玉山县</span>
                    </div>
                    <div class="picitem" style="margin-bottom:8px;" @click="changeWeb('铅山县')">
                        <img src="/img/webpic4.png" alt="">
                        <span>铅山县</span>
                    </div>
                    <div class="picitem" style="margin-bottom:0;" @click="changeWeb('上饶经济技术开发区')">
                        <img src="/img/webpic7.png" alt="">
                        <span>上饶经济技术开发区</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="zzbox">
            <div class="zzitem" @click="changeOrg(null)">上饶总站</div>
        </div>
        <div class="fzlistbox flex align-center">
            <div class="fzitem" v-for="item in orlist" @click="changeOrg(item)">{{item.districtName}}</div>
        </div>
    </div>
</template>
<script>
import userRequest from '@/api/user';
export default{
    data(){
        return{
            orlist:[],
        }
    },
    created(){
        this.getOrg()
    },
    methods:{
        changeWeb(title){
            let find=this.orlist.find((n)=>(n.districtName==title))
            if(find){
                this.changeOrg(find)
            }
        },
        getOrg(){
            userRequest.orgList({id:240}).then(res=>{
                if(res.code==200){
                    console.log(res)
                    this.orlist=res.data
                }
            })
        },
        changeOrg(row){
            this.$store.commit("changeOrgid",row)
            // location.reload()
            this.$router.push("/")
        },
    }
}
</script>
<style scoped>
.picbox{ margin: 15px 0; padding: 12px 0;}
.pictitle{ width: 1010px; margin: 0 auto;}
.pictitle strong{ font-size: 18px;}
.piccont{ 
    width: 1014px;
    margin: 8px auto 0 auto;
    justify-content: space-between;
    

}
.picleft{ width: 598px;flex-wrap: wrap;}
.picitem{  cursor: pointer; width: 200px; position: relative;  height: 200px; margin-right: 8px; margin-bottom: 8px; overflow: hidden;}
.picitem img{ transition: all 1s;}
.picright{ width: 408px; flex-wrap: wrap; height: 408px; flex-direction: column;}
.picitem span{ position: absolute; z-index: 5000; display: block; bottom: 18px; left: 18px; font-weight: bold; font-size: 18px; color: #ffffff;}
.picitem:hover img{ transform: scale(1.1);}
.zzbox{ width: 1010px; margin:35px auto 0 auto;}
.zzitem{ background: #ffffff; font-weight: bold; cursor: pointer;  width: 150px; height: 50px; display: flex; align-items: center; justify-content: center;}
.fzitem{ background: #ffffff; cursor: pointer; margin-right: 20px; margin-bottom: 15px;  width: 150px; height: 50px; display: flex; align-items: center; justify-content: center;}
.zzitem:hover{ color: #298dff;}
.fzlistbox{ width: 1010px; margin:25px auto 0 auto; flex-wrap: wrap; padding-bottom: 30px;}
.fzitem:nth-child(6n){ margin-right: 0;}
.fzitem:hover{ box-shadow: rgba(0,0,0,0.1) 0 0 12px;}


</style>

